import React, { useLayoutEffect, useContext } from 'react';
import Layout from '../components/layout';
import { ThemeContext } from '../providers/theme';
import Products from '../components/Products';
import InfoCards from '../components/InfoCard';
import InfoContainer from '../components/InfoContainer';
import SEO from '../components/seo';
import { handleProducts } from '../utils/handleProducts';
import { graphql, useStaticQuery } from 'gatsby';
import Breadcrumbs from '../components/Breadcrumbs';

function Rose() {
  const { setTheme } = useContext(ThemeContext);

  useLayoutEffect(() => {
    setTheme('rose');
  }, []);

  const data = handleProducts(
    useStaticQuery(graphql`
      {
        allSitePage(
          filter: {
            context: {
              productType: { eq: "rose" }
              isProduct: { eq: "true" }
            }
          }
        ) {
          edges {
            node {
              path
              context {
                productTitle
                productPrice
                productOldprice
                productLink
                productImage
                productDescription
                productCategory
                productAvailable
                productType
                productId
              }
            }
          }
        }
      }
    `)
  );

  return (
    <React.Fragment>
      <SEO
        title={'Rosé'}
        description={
          'Find den helt rigtige rosé. Vi samler udvalg i samarbejde med en håndfuld af landets vinforhandlere, for at gøre det nemt for dig at finde den helt rette flaske'
        }
      />
      <Layout themeColor="#ee7272" title="Rosé">
        <Products type="rosé" data={data} />
        <InfoCards
          firstColumn={{
            title: 'Rosé til enhver smag',
            text:
              'Hos vinkammeret.dk kan du finde et hav af forskellige slags rosévine, både søde og tørre. Det er vigtigt for os at præsentere dig for mange forskellige slags, da vi på den måde sikrer, at du finder den rosé, som passer dig og dine smagsløg allerbedst.'
          }}
          secondColumn={{
            title: 'Flere forskellige slags rosé',
            text:
              'Der findes mange forskellige slags rosévine, som bliver produceret på forskellige druer. De mest populære druer, som bruges til rosé er Garnacha, Sangiovese Rosé, mourvédre og Pinot Noir. '
          }}
          thirdColumn={{
            title: 'Rosé fra forskellige lande',
            text:
              'Du kan købe rosévin fra flere forskellige verdensdele, der bliver produceret i både nord- og sydamerika, Australien, Sydafrika og Europa. Italien, Frankrig, Spanien, USA og Australien er dog de største producenter af rosévinen. '
          }}
        />
        <InfoContainer
          title={'Vidste du...'}
          text={
            'At rosévin er et let og lyserød vin, som oftest bliver produceret på røde druer. Mange tror at rosévin bliver lavet ved at blande rødvin og hvidvin, men det er ikke korrekt. Rosé bliver fremstillet ved at knuse druerne, hvorefter man macerere most og skaller sammen i kort tid, ca. mellem 6-48 timer. Derefter bliver skallerne siet fra og så gærer vinen videre. Man kan dog også tappe noget most fra en rødvin, som så kan gære færdigt som rosé. \n\nGrunden til at man fjerner druernes skaller efter få timer, er fordi skallerne kun har afgivet en lille smule garvesyre, hvilket gør at vinen får den karakteristiske lyserøde farve. \n\nI modsætning til både rød og hvidvin, bliver rosévinen ikke bedre med alderen, man skal helst drikke rosé indenfor de første to år, jo ældre rosévinen bliver, jo mørkere i farven den bliver. \n\nRosévin er rigtig god til mad, specielt snacks, forretter og grillretter. Den bruges dog også ofte som velkomstdrink eller til hyggestunden på terrassen, da mange forbinder rosévin med sommer.'
          }
        />
        <Breadcrumbs type={'rose'} />
      </Layout>
    </React.Fragment>
  );
}

export default Rose;
